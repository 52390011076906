import React from 'react';
import {Container, Row, Col, Image} from 'reactstrap'
import SectionProgress from '../../views/index-sections/SectionProgress'

const TechnicalSkills = () => {
    return (
        <SectionProgress/>
      );
}
 
export default TechnicalSkills;